import { memo, useContext, useState } from "react";
import { Sidebar, Menu, MenuItem, sidebarClasses } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TbLayoutSidebarRightCollapse } from "react-icons/tb";
import { ColorModeContext, tokens } from "../../theme";
import { MdDarkMode, MdOutlineLightMode } from "react-icons/md";
import { makeStyles } from "@mui/styles";
import {
  ChargerIcon,
  DashboardIcon,
  IdlingIcon,
  LogoutIcon,
  ReportIcon,
  ReservationIcon,
  RestrictedIcon,
  TransactionsIcon,
} from "../../assets/icons/sidebar";
import { Logo, LogoDark } from "../../assets/icons";
// import { useQuery } from "react-query";
import { ROUTES } from "../../routers";

export const ArrMenuSideBar = [
  {
    title: "Dashboard",
    to: "/dashboard",
    icon: <DashboardIcon />,
  },
  {
    title: "Charger point",
    to: "/charger-point",
    icon: <TransactionsIcon />,
  },
  {
    title: "Charge sessions",
    to: "/charge-sessions",
    icon: <ChargerIcon />,
  },
  {
    title: "Transactions",
    to: "/transactions",
    icon: <TransactionsIcon />,
  },
  {
    title: "Location",
    to: "/location",
    icon: <ChargerIcon />,
  },
  {
    title: "Evse",
    to: ROUTES.EVSE,
    icon: <ChargerIcon />,
  },
  {
    title: "Idling penalty",
    to: "/idling-penalty",
    icon: <IdlingIcon />,
  },
  {
    title: "Reservation",
    to: "/reservation",
    icon: <ReservationIcon />,
  },
  {
    title: "Restricted user",
    to: "/user",
    icon: <RestrictedIcon />,
  },
  {
    title: "Payouts",
    to: "/payouts",
    icon: <ChargerIcon />,
  },
  {
    title: "Reports",
    to: "/reports",
    icon: <ReportIcon />,
  },
  {
    title: "Promo Code",
    to: "/promo-codes",
    icon: <ChargerIcon />,
  },
  {
    title: "Promo Program",
    to: "/promo-programs",
    icon: <ChargerIcon />,
  },
  {
    title: "Subscription Plans",
    to: "/subscription-plans",
    icon: <ReportIcon />,
  },
  {
    title: "Subscriptions",
    to: "/subscriptions",
    icon: <ReportIcon />,
  },
];

export const Item = memo(
  ({
    title,
    to,
    icon,
    location,
    isCollapsed,
  }: {
    title: string;
    to: string;
    icon: React.ReactNode;
    location: string;
    isCollapsed?: boolean;
  }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isActive = location === to;
    return (
      <MenuItem
        style={{
          backgroundColor: isActive && isCollapsed ? "#c7c7c7" : "unset",
        }}
        component={<Link to={to} />}
        icon={icon}
      >
        <Typography
          color={isActive ? colors.primary["activeText"] : "#c7c7c7"}
          fontSize={"16px"}
          fontWeight={isActive ? "700" : "400"}
        >
          {title}
        </Typography>
      </MenuItem>
    );
  },
);

const useStyles = makeStyles<Record<string, any>>({
  btnToggleDarkMode: {
    outline: "none",
    border: "none",
    background: "inherit",
    cursor: "pointer",
    display: "flex",
    alignItem: "center",
    position: "absolute",
    top: "10px",
    right: "23px",
  },
});

const SidebarLayout = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const location = useLocation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const classes = useStyles() as any;
  const navigate = useNavigate();
  // const { data: profile } = useQuery("profile") as any;

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("profile");
    navigate(ROUTES.LOGIN);
  };

  return (
    <Box
      position={"relative"}
      minHeight={"100%"}
      sx={{
        borderRadius: {
          xs: "0px",
          md: "20px",
        },
        maxHeight: {
          lg: "926px",
        },
      }}
      overflow={"hidden"}
    >
      <Sidebar
        rootStyles={{
          minHeight: "100%",
          overflowY: "auto",
          paddingRight: "0px",
          maxHeight: "100%",
          [`.${sidebarClasses.container}`]: {
            backgroundColor: colors.primary["bg"],
            padding: "50px 20px",
            maxHeight: "100%",
            display: !isCollapsed ? "flex" : "block",
          },
        }}
        collapsed={isCollapsed}
      >
        <Menu
          rootStyles={{
            minHeight: "calc(100vh - 40px)",
            flex: 1,
            [`ul`]: {
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: "20px",
            },
          }}
          menuItemStyles={{
            button: {
              padding: 0,
              borderRadius: "5px",
            },
          }}
        >
          {/* Change Dark/Light Mode */}
          <button
            onClick={colorMode.toggleColorMode}
            className={classes.btnToggleDarkMode}
          >
            {theme.palette.mode === "dark" ? (
              <MdOutlineLightMode color="#ffc107" size={30} />
            ) : (
              <MdDarkMode color="#ffc107" size={30} />
            )}
          </button>
          {/* ========= Change Dark/Light Mode ========= */}
          <Box>
            <Box marginBottom={"50px"}>
              {!isCollapsed ? (
                <>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box
                      borderRadius={"10px"}
                      overflow={"hidden"}
                      height={"48px"}
                      width={"53px"}
                    >
                      <img
                        height={"48px"}
                        width={"53px"}
                        src={theme.palette.mode === "light" ? Logo : LogoDark}
                        alt="Logo"
                      />
                    </Box>
                    <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                      <TbLayoutSidebarRightCollapse
                        color={colors.primary.activeText}
                      />
                    </IconButton>
                  </Box>
                  {/* <Box marginTop={"15px"}>
                    <Typography
                      component={"h2"}
                      fontWeight={"700"}
                      fontSize={"31px"}
                      marginBottom={0}
                    >
                      {profile?.role?.name}
                    </Typography>
                    <Typography
                      fontWeight={"300"}
                      fontSize={"13px"}
                      component={"span"}
                      color={"#9B9B9B"}
                    >
                      {profile?.email}
                    </Typography>
                  </Box> */}
                </>
              ) : (
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <TbLayoutSidebarRightCollapse
                    color={colors.primary.activeText}
                  />
                </IconButton>
              )}
            </Box>

            <Box>
              {ArrMenuSideBar.map((item, index) => (
                <Item
                  key={index}
                  title={item.title}
                  to={item.to}
                  icon={item.icon}
                  location={location.pathname}
                  isCollapsed={isCollapsed}
                />
              ))}
            </Box>
          </Box>
          <Box
            onClick={handleLogout}
            paddingLeft={!isCollapsed ? "15px" : "0px"}
            height={"48px"}
            display={"flex"}
            alignItems={"center"}
            gap={"20px"}
            style={{ cursor: "pointer" }}
          >
            <LogoutIcon color={colors.primary.activeText} />
            {!isCollapsed && (
              <Typography
                color={"#c7c7c7"}
                fontSize={"16px"}
                fontWeight={"700"}
              >
                Logout
              </Typography>
            )}
          </Box>
        </Menu>
      </Sidebar>
    </Box>
  );
};

export default SidebarLayout;
